import { format, distanceInWords, differenceInDays } from "date-fns";
import React from "react";
import { Link } from "gatsby";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";
import BlockText from "./block-text";
import Container from "./container";
import RoleList from "./role-list";
import { Figure } from "./figure";

import { responsiveTitle3, link } from "./typography.module.css";
import { text, details } from "./product-preview.module.css";
import * as styles from "./product.module.css";

function Product(props) {
  const {
    _rawBody,
    title,
    mainImage,
    publishedAt,
    _rawDescription,
    _rawGallery,
    location,
  } = props;
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.gridLayout}>
          <div className={styles.mainContent}>

          </div>
          <aside className={styles.metaContent}>
            <div>
            
                {_rawDescription && (
                  <BlockText blocks={_rawDescription} />
                )}            
            </div>
          </aside>
          
        </div>
        <div className={styles.gallery}>
            {_rawGallery?.map((image, index) => (<React.Fragment key={image.asset?.id}><Figure columns={image.columns} node={image} />{index % 2 ? <></> : <></>}</React.Fragment>))}
          </div>
      </Container>
    </article>
  );
}

export default Product;
